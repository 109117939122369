<template>
    <properties-block v-bind="$props" v-on="$listeners">
        <b-form-group label-cols="4" :label="'Transition|Transition'|tr">
            <b-select v-model="block.transition">
                <option value="slide" v-tr>Slide|Glissement</option>
                <option value="fade" v-tr>Fade|Fondu</option>
                <option value="none" v-tr>No animation|Sans animation</option>
            </b-select>
        </b-form-group>
        <b-form-group label-cols="4" :label="'Duration|Affichage'|tr">
            <b-select v-model.number="block.interval">
                <option v-for="n in 28" :value="(n + 2) * 1000">{{n + 2}} secs</option>
            </b-select>
        </b-form-group>
        <b-form-group>
            <btn-responsive-prop v-model="block" field="indicators" for-checkbox/>
            <b-checkbox v-model="block.mediaProxy.indicators">
                <span v-tr>Display Indicators|Afficher les indicateurs</span>
            </b-checkbox>
        </b-form-group>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-slideshow #hot-reload-debug
export default {
    name: `properties-block-slideshow`,
    components: {PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
